import React from 'react';

function App() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      <header className="w-full -mb-20">
        <div className="max-w-6xl mx-auto px-5 sm:px-6">
          <div className="flex items-center justify-between h-16 md:h-20">
            <div className="flex-shrink-0 mr-4">
              <a className="block" href="/"><img src="/images/blocdraig.png" alt="Bloc Draig" /></a>
            </div>
            <nav className="flex flex-grow">
              {/*<ul className="flex flex-grow justify-end flex-wrap items-center">*/}
              {/*  <li><a className="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-sm leading-tight uppercase rounded shadow hover:bg-red-700 hover:shadow-md focus:bg-red-700 focus:shadow-md focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-md transition duration-150 ease-in-out" href="#contact">Contact</a></li>*/}
              {/*</ul>*/}
            </nav>
          </div>
        </div>
      </header>

      <main className="flex-grow">

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="text-center pb-12 md:pb-16">
                <h1 className="text-7xl md:text-8xl font-extrabold leading-tighter tracking-tighter mb-4">Practical blockchain <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-red-800 pr-1">solutions</span></h1>
                <div className="max-w-3xl mx-auto">
                  <p className="text-xl text-gray-600 mb-8">
                    Delivering practical and easy-to-use products that aid the mass adoption of blockchain and NFT technologies.
                  </p>
                  {/*<div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">*/}
                  {/*  <div><a className="inline-block px-6 py-3 bg-red-600 text-white font-medium leading-tight uppercase rounded shadow hover:bg-red-700 hover:shadow-md focus:bg-red-700 focus:shadow-md focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-md transition duration-150 ease-in-out w-full mb-4 sm:w-auto sm:mb-0" href="#0">Start free trial</a></div>*/}
                  {/*  <div><a className="inline-block px-6 py-3 bg-black text-white font-medium leading-tight uppercase rounded shadow hover:bg-black hover:shadow-md focus:bg-black focus:shadow-md focus:outline-none focus:ring-0 active:bg-black active:shadow-md transition duration-150 ease-in-out w-full sm:w-auto sm:ml-4" href="#0">Learn more</a></div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative">
          <div className="absolute inset-0 bg-gradient-to-br from-stone-50 to-stone-200 pointer-events-none" aria-hidden="true"></div>
          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-8 md:py-14">
              <div className="mx-auto text-center">
                <h2 className="text-5xl font-semibold leading-tighter tracking-tighter mb-6">Products</h2>
                <div className="text-gray-600 flex flex-wrap justify-center items-stretch -m-3">

                  <a className="block max-w-sm md:w-80 grow-0 p-6 m-3 bg-white rounded shadow-md relative"
                     href="https://waxitems.com">
                    <div
                      className="absolute inline-block top-0 right-auto bottom-auto left-2/4 -translate-x-2/4 -translate-y-1/2 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 py-2 px-3.5 text-xs leading-none text-center whitespace-nowrap align-baseline font-semibold text-white rounded-full z-10 bg-gradient-to-r from-red-600 to-red-700">
                      In Development
                    </div>
                    <div className="mb-4">
                      <img src="/images/waxitems.png" alt="Wax Items" className="inline max-h-8 max-w-full"/>
                    </div>
                    <p>
                      NFT and block explorer for the WAX blockchain.
                    </p>
                  </a>

                  <a className="block max-w-sm md:w-80 grow-0 p-6 m-3 bg-white rounded shadow-md"
                     href="https://waxcpu.com">
                    <div className="mb-4 max-w-full">
                      <img src="/images/waxcpu.png" alt="Wax Items" className="inline max-h-8 max-w-full"/>
                    </div>
                    <p>
                      24-hour CPU loan service for the WAX blockchain.
                    </p>
                  </a>

                  <a className="block max-w-sm md:w-80 grow-0 p-6 m-3 bg-white rounded shadow-md"
                     href="https://wax-health.blocdraig.com">
                    <div className="mb-4 max-w-full text-2xl font-bold">
                      WAX Health
                    </div>
                    <p>
                      Health check for various APIs provided by WAX Guilds.
                    </p>
                  </a>

                  <a className="block max-w-sm md:w-80 grow-0 p-6 m-3 bg-white rounded shadow-md"
                     href="https://wax-atomic-health.blocdraig.com">
                    <div className="mb-4 max-w-full text-2xl font-bold">
                      WAX Atomic Health
                    </div>
                    <p>
                      Health check for WAX Atomic Assets API endpoints.
                    </p>
                  </a>

                </div>
              </div>
            </div>
          </div>
          {/*<div className="relative pb-6 md:pb-10">*/}
          {/*  <div className="absolute inset-0 top-32 md:top-10 lg:top-1/3 md:mt-24 lg:mt-0 bg-gradient-to-br from-stone-500 to-stone-800 pointer-events-none" aria-hidden="true"></div>*/}
          {/*  <div className="relative max-w-6xl mx-auto px-4 sm:px-6">*/}
          {/*    <div className="pb-6 md:pb-10">*/}
          {/*      <div className="mx-auto text-center">*/}
          {/*        <h2 className="text-4xl font-semibold leading-tighter tracking-tighter mb-6">Services</h2>*/}
          {/*      </div>*/}
          {/*      <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">*/}
          {/*        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-md">*/}
          {/*          <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Initial Contact</h4>*/}
          {/*          <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          {/*        </div>*/}
          {/*        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-md">*/}
          {/*          <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Discovery Session</h4>*/}
          {/*          <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          {/*        </div>*/}
          {/*        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-md">*/}
          {/*          <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Contracting</h4>*/}
          {/*          <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          {/*        </div>*/}
          {/*        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-md">*/}
          {/*          <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Fast Prototyping</h4>*/}
          {/*          <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          {/*        </div>*/}
          {/*        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-md">*/}
          {/*          <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Design Phase</h4>*/}
          {/*          <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          {/*        </div>*/}
          {/*        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-md">*/}
          {/*          <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Develop &amp; Launch</h4>*/}
          {/*          <p className="text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </section>

      </main>

      <footer>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/*<div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">*/}
          {/*  <div className="sm:col-span-12 lg:col-span-3">*/}
          {/*    <div className="mb-2">*/}
          {/*      <a className="inline-block" aria-label="Bloc Draig" href="/">*/}
          {/*        <img src="/images/blocdraig.png" className="h-8" />*/}
          {/*    </a>*/}
          {/*    </div>*/}
          {/*    <div className="text-sm text-gray-600">*/}
          {/*      <a className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out" href="/">Terms</a> · <a className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out" href="/">Privacy Policy</a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">*/}
          {/*    <h6 className="text-gray-800 font-medium mb-2">Products</h6>*/}
          {/*    <ul className="text-sm">*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Web Studio</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">DynamicBox Flex</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Programming Forms</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Integrations</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Command-line</a></li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">*/}
          {/*    <h6 className="text-gray-800 font-medium mb-2">Resources</h6>*/}
          {/*    <ul className="text-sm">*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Documentation</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Tutorials &amp; Guides</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Blog</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Support Center</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Partners</a></li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">*/}
          {/*    <h6 className="text-gray-800 font-medium mb-2">Company</h6>*/}
          {/*    <ul className="text-sm">*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Home</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">About us</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Company values</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Pricing</a></li>*/}
          {/*      <li className="mb-2"><a className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" href="/">Privacy Policy</a></li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">*/}
          {/*    <h6 className="text-gray-800 font-medium mb-2">Subscribe</h6>*/}
          {/*    <p className="text-sm text-gray-600 mb-4">Get the latest news and articles to your inbox every month.</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              <li>
                <a className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter" href="https://twitter.com/BlocDraig">
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"></path>
                </svg>
              </a>
              </li>
            </ul>
            <div className="text-sm text-gray-600 mr-4">Copyright &copy; {new Date().getFullYear()} Bloc Draig</div>
          </div>
        </div>
      </footer>

    </div>
  );
}

export default App;
